import React, { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap"
import * as style2 from "../buyerdetaipages/buyerdetail.module.css";
import AvatarImage from '../../components/Avatar/avatarImage'
import { buyerList, buyerGroupList } from "../../components/Api/ListingApi"
import * as styled from "../../pages/buyers/buyer.module.css"

export default function BuyerListingDetail(props) {
    const buyerData = props.data
    // const [loader, setLoader] = useState(false);
    // const [isBuyerFounded, setBuyerFounded] = useState(false)
    const dataSort=-1
    // const [buyer, setBuyer] = useState([])
    const search=""
    const [buyerGroup, setBuyerGRoup] = useState([])
    useEffect(() => {
        async function fetchData() {
            // setLoader(true);
            listingApis(search, dataSort);
            buyerGroupListing()
        } fetchData();

    }, [])
    const listingApis = async (searchs, dataSort) => {
        let userId = localStorage.getItem('userId')
        let getParams = {
            id: userId,
            status: 'accept',
            search: searchs,
            sort_field: "_id",
            sort_value: dataSort
        }

        // setLoader(true);

        await buyerList(getParams).then(async (res) => {
            // setLoader(false);
            // setLoader(false);
            // res.data.data.length !== 0 ? setBuyerFounded(false) : setBuyerFounded(true)
            // setBuyer(res.data.data)
        }).catch((error) => {
            console.log(error)
            // setLoader(false)
        })
    }
    const buyerGroupListing = async () => {
        let userId = localStorage.getItem('userId')
        let getParams = {
            user_id: buyerData._id._id,
        }
        // setLoader(true);
        await buyerGroupList(userId, getParams).then(async (res) => {
            // setLoader(false);
            setBuyerGRoup(res.data.data)
        }).catch((error) => {
            console.log(error)
            // setLoader(false)
        })
    }



    return (
        <div>
            <div className='tourbuyer mb-5' >
                <div className="col-8" style={{ height: "950px", overflow: "auto", }}>
                    {buyerGroup.map((data, i) => (
                        <Row key={i} style={{
                            border: "1px solid lightgrey",
                            borderRadius: "20px",
                            boxShadow: "1px 2px 4px 2px #e4e5e7",
                            margin: "30px 0 0 0",
                            padding: "20px 10px",
                            background: "#fff",
                        }}>
                            <Col lg="6">
                                <Row>
                                    <Col lg="5" className={style2.ListingdetailhomeImage}>
                                        {data.user_id.img ?
                                            < img src={process.env.API_IMAGE_URL + data.user_id.img} alt='userImage' className={styled.cardRowImg} crossOrigin="anonymous" />
                                            : <>{data.user_id.name ? <div style={{ width: '88px', height: '88px', margin: 'auto', position: "relative" }}><AvatarImage fontSize={'38px'} data={data.user_id.name ? data.user_id.name : ''} /></div>
                                                : null}</>
                                        }
                                    </Col>
                                    <Col lg="7" className='sellertour-margin'>
                                        <div className={style2.sellertour}>
                                            <div className={style2.sellertourAddressPrice}>
                                                <h6 className={style2.addresslist}>Name </h6>
                                            </div>
                                            <p className={style2.useName}>{data.user_id.name}</p>

                                            <div className={style2.pricedetail}>
                                                <h6>Status</h6>
                                                <span className={style2.cardRowSpan}>
                                                    {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="3" className='sellertour-margin'>
                                <h6>Contact</h6>
                                <p>Profile Status</p>
                                <p>{data.user_id.profile_status}%</p>
                            </Col>
                            <Col lg="3" className='sellertour-margin'>
                                <p className="">Saves</p>
                                <h6>{data.saves}</h6>
                            </Col>
                        </Row>
                    ))}
                </div>

            </div >

        </div >
    )
}
